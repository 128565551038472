import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/ui/layout/layout'
import Hero from '../components/ui/hero/hero'
import Header from '../components/ui/header/header'
import DocumentationList from '../components/ui/documentation-list/documentation-list'
import SEO from "../components/seo"

function DeveloperPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {posttype: {eq: "doc"}}}, sort: {order: ASC, fields: frontmatter___order}) {
        edges {
          node {
            id
            frontmatter {
              posttype
              title
              description
              icon
              order
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Layout hasNoBorder={true}>
      <SEO title="Documentation" description="The hub location for guides, and information about building with Bazar." />
      <Header />
      <Hero isSmall={true}>
        <h1 className="section-hero__title">Documentation</h1>
        <p className="section-hero__description">A place where you can start learning.</p>
      </Hero>
      <main id="content" style={{"backgroundColor": "#f8fbff"}}>
        <DocumentationList items={data.allMarkdownRemark.edges} />
      </main>
    </Layout>
  )
}

export default DeveloperPage