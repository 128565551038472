import React from "react"
import clsx from "clsx";

function Hero(props) {
  return (
    <section className={clsx({"section-hero" : true, "is-small" : props.isSmall, "is-news" : props.isNews})}>
      <div className="section-hero__inner">
        {props.children} 
      </div>
    </section>
  )
}

export default Hero
