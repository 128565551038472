import React from "react"
import { Link } from "gatsby"
import documentationStyles from "./documentation-list.module.scss"
import Alert from "../alert.js"

function NewsList(props) {
  return (
    <section id="documentation" className={documentationStyles.listWrapper}>
      <Alert type="warning" content="Please note that Bazar is currently in beta version. We are working hard on the first major release. We think it is suitable for production in its current state but be careful because there may be new releases more often." />
      <div className={documentationStyles.listInside}>
        {props.items.map((item) => {
          return (
            <Link to={`/docs/${item.node.fields.slug}`} className={documentationStyles.card} key={item.node.id}>
              <img src={item.node.frontmatter.icon} alt="" className={documentationStyles.cardIcon} />
              <div className={documentationStyles.cardContent}>
                <h3 className={documentationStyles.cardTitle}>{item.node.frontmatter.title}</h3>
                <p className={documentationStyles.cardDescription}>{item.node.frontmatter.description}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default NewsList
